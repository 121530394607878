import React from "react";
import { IPlayerState, EndGameState } from "Routes";
import "./HealthStatus.css";

interface IHealthStatusProps {
  loggedInPlayer: IPlayerState;
}

export function HealthStatus(props: IHealthStatusProps) {
  const { loggedInPlayer } = props;

  let healthStatus = `Morale: ${loggedInPlayer.health}/10`;
  if (loggedInPlayer.endGameState === EndGameState.eliminated) {
    healthStatus = "Fired";
  } else if (loggedInPlayer.endGameState === EndGameState.won) {
    healthStatus = "Winner";
  }

  return <div className={"HealthStatus"}>{healthStatus}</div>;
}

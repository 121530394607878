import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
// import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDbQNHuMdTFWlYkF3kbK3aipSdtFh6FwbU",
  authDomain: "coworkers-c2847.firebaseapp.com",
  projectId: "coworkers-c2847",
  storageBucket: "coworkers-c2847.appspot.com",
  messagingSenderId: "860918688191",
  appId: "1:860918688191:web:81a8e1fbd96ae357d4d90a",
  measurementId: "G-BDMET8HLNQ",
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

// firestore.useEmulator("localhost", 8080);
// firebase.functions().useEmulator("localhost", 5001);

export const user = {
  firestore,
  address: "",
  connectMetamask: () => {},
  playerName: "",
  setPlayerName: (name: string) => {},
};

export const UserContext = React.createContext(user);

import React from "react";
import {
  IGameState,
  IPlayerState,
  PlayStatus,
  GameStatus,
  EndGameState,
} from "./Game.interfaces";
import { Flag, Star, FiberManualRecord } from "@material-ui/icons";
import {
  CoworkerSelector,
  CoworkerId,
  Coworker,
  HealthStatus,
  ICardProps,
  CardType,
  WorkStatus,
} from "Components";

export interface IPlayerGridProps {
  gameState?: IGameState;
  playerStateMap?: Map<string, IPlayerState>;
  loggedInPlayer: () => IPlayerState | undefined;
  setSelectedPlayer: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedPlayer?: string;
  address: string;
  isLoggedInPlayer: (player: IPlayerState) => boolean;
  updatePlayerCoworkerField: (id: CoworkerId) => void;
  isLoggedInPlayerReady: () => boolean | undefined;
  updatePlayerReadyField: () => void;
  selectedCard: ICardProps | undefined;
}

export function PlayerGrid(props: IPlayerGridProps) {
  const {
    gameState,
    playerStateMap,
    loggedInPlayer,
    setSelectedPlayer,
    selectedPlayer,
    address,
    isLoggedInPlayer,
    updatePlayerCoworkerField,
    isLoggedInPlayerReady,
    updatePlayerReadyField,
    selectedCard,
  } = props;

  const [hoveredTarget, setHoveredTarget] = React.useState<string>();

  React.useEffect(() => {
    // During play phase, if selected card is evade, healSelf, attackAll or work, set the selected player to self.
    if (
      selectedCard &&
      loggedInPlayer()?.status === PlayStatus.play &&
      [
        CardType.evade,
        CardType.healSelf,
        CardType.attackAll,
        CardType.work,
      ].includes(selectedCard.cardType)
    ) {
      setSelectedPlayer(address);
    }
    // If no card is selected, clear the selected player.
    else if (!selectedCard) {
      setSelectedPlayer(undefined);
    }
  }, [selectedCard, address, setSelectedPlayer, loggedInPlayer]);

  const isGameStarted = () => {
    return (
      gameState?.status === GameStatus.inProgress ||
      gameState?.status === GameStatus.finished
    );
  };

  const isPlayerSelectable = (player: IPlayerState): boolean => {
    if (
      selectedPlayer ||
      loggedInPlayer()?.status !== PlayStatus.play ||
      player.endGameState === EndGameState.eliminated ||
      (selectedCard &&
        [
          CardType.attackAll,
          CardType.evade,
          CardType.healSelf,
          CardType.work,
        ].includes(selectedCard.cardType))
    ) {
      return false;
    }

    // If player to select is not self and selected card is attackOne, groupAttack, healOther, or preventAction,
    // the player is selectable.
    if (
      !isLoggedInPlayer(player) &&
      selectedCard &&
      [
        CardType.attackOne,
        CardType.groupAttack,
        CardType.healOther,
        CardType.preventAction,
      ].includes(selectedCard.cardType)
    ) {
      return true;
    }
    // If card has not been selected, the player is selectable.
    return !selectedCard;
  };

  const isPlayerSelected = (player: IPlayerState): boolean => {
    if (!selectedPlayer) {
      return false;
    }

    // If selected card is attackAll and player is not self, player should show as selected.
    // Or if selected card is not attackAll, simply show the selected player as selected.
    return (
      (selectedCard?.cardType === CardType.attackAll &&
        !isLoggedInPlayer(player)) ||
      (selectedCard?.cardType !== CardType.attackAll &&
        selectedPlayer === player.address)
    );
  };

  const grid =
    gameState &&
    gameState.playerPositions.map((positionAddress) => {
      const player = playerStateMap?.get(positionAddress);

      const selectPlayer = () => {
        if (player) {
          if (isPlayerSelectable(player)) {
            setSelectedPlayer(player?.address);
          } else {
            setSelectedPlayer(undefined);
          }
        }
      };

      const handlePlayerMouseEnter = (player: IPlayerState) => {
        return () => {
          isPlayerSelectable(player) && setHoveredTarget(player.address);
        };
      };

      const handlePlayerMouseLeave = () => {
        setHoveredTarget(undefined);
      };

      if (player) {
        let playerClassName = isPlayerSelected(player)
          ? "Grid__Main__Player Grid__Main__Player__Selected"
          : "Grid__Main__Player";

        if (hoveredTarget === player.address) {
          playerClassName += " Grid__Main__Player__Hovered";
        }

        return (
          <div
            className={playerClassName}
            key={player.address}
            onClick={selectPlayer}
            onMouseEnter={handlePlayerMouseEnter(player)}
            onMouseLeave={handlePlayerMouseLeave}
          >
            <div className="PlayerName">
              {(player.name || player.address).slice(0, 15)}
              {gameState.host === player.address && (
                <Flag className="Host-Icon" />
              )}
              {address === player.address && (
                <Star className="Current-Player-Icon" />
              )}
            </div>
            {isLoggedInPlayer(player) && !isGameStarted() ? (
              <>
                <CoworkerSelector
                  changeSelection={updatePlayerCoworkerField}
                  isSelectionEnabled={!isLoggedInPlayerReady()}
                  selection={player.coworker}
                />
                {isLoggedInPlayerReady() ? (
                  <button
                    className="ReadyToggle ReadyToggle-Ready"
                    onClick={updatePlayerReadyField}
                  >
                    Ready
                  </button>
                ) : (
                  <button
                    className="ReadyToggle ReadyToggle-NotReady"
                    onClick={updatePlayerReadyField}
                  >
                    Click here when ready
                  </button>
                )}
              </>
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <Coworker id={player.coworker || CoworkerId.purple} />
                  {isGameStarted() &&
                  player.endGameState === EndGameState.playing ? (
                    player.status === PlayStatus.finished ? (
                      <FiberManualRecord className="Player-Status-Finished" />
                    ) : (
                      <FiberManualRecord className="Player-Status-Not-Finished" />
                    )
                  ) : undefined}
                </div>
                {isGameStarted()
                  ? null
                  : player.readyToStartGame
                  ? "Ready"
                  : "Not Ready"}
                {isGameStarted() && <HealthStatus loggedInPlayer={player} />}
                {isGameStarted() && <WorkStatus playerState={player} />}
              </>
            )}
          </div>
        );
      } else {
        return null;
      }
    });

  return <>{grid}</>;
}

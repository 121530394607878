import React from "react";
import "./CoworkerSelector.css";
import { Coworker, CoworkerId } from "Components";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

interface ICoworkerSelectorProps {
  changeSelection: (Coworker: CoworkerId) => void;
  isSelectionEnabled: boolean;
  selection?: CoworkerId;
}

export function CoworkerSelector(props: ICoworkerSelectorProps) {
  const coworkers = React.useMemo(
    () => [
      CoworkerId.purple,
      CoworkerId.blue,
      CoworkerId.green,
      CoworkerId.yellow,
      CoworkerId.red,
    ],
    []
  );
  const { changeSelection, isSelectionEnabled, selection } = props;
  let initialIndex = 0;
  if (selection) {
    initialIndex = coworkers.findIndex((coworker) => coworker === selection);
  }
  const [coworkerIndex, setCoworkerIndex] = React.useState(initialIndex);
  const coworkersRef = React.useRef<CoworkerId[]>(coworkers);
  const [showTooltip, setShowTooltip] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);
    return () => clearTimeout(timer);
  });

  const handleTooltipOpen = () => {
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const nextCoworker = () => {
    const nextIndex = (coworkerIndex + 1) % 5;
    changeSelection(coworkersRef.current[nextIndex]);
    setCoworkerIndex(nextIndex);
  };

  const previousCoworker = () => {
    const previousIndex = (coworkerIndex - 1 + 5) % 5;
    changeSelection(coworkersRef.current[previousIndex]);
    setCoworkerIndex(previousIndex);
  };

  return (
    <div className="CoworkerSelector">
      {isSelectionEnabled && (
        <Tooltip
          title="Use arrows to select character"
          placement={"left"}
          arrow={true}
          enterNextDelay={5000}
        >
          <button className="SelectButton" onClick={previousCoworker}>
            <ChevronLeft fontSize={"large"} />
          </button>
        </Tooltip>
      )}
      <Coworker id={coworkersRef.current[coworkerIndex]} />
      {isSelectionEnabled && (
        <Tooltip
          title="Use arrows to select character"
          open={showTooltip}
          arrow={true}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          placement={"right"}
          enterNextDelay={5000}
        >
          <button className="SelectButton" onClick={nextCoworker}>
            <ChevronRight fontSize={"large"} />
          </button>
        </Tooltip>
      )}
    </div>
  );
}

import React from "react";
import "./Game.css";
import { UserContext } from "UserContext";
import { useParams } from "react-router-dom";
import {
  CoworkerId,
  Deck,
  Hand,
  DiscardPile,
  ICardProps,
  Inbox,
  InboxButton,
  Timer,
} from "Components";
import {
  GameStatus,
  IGameState,
  IPlayerState,
  IRoundState,
  PlayStatus,
  RoundStatus,
  IMove,
  TargetMap,
} from "./Game.interfaces";
import { Header } from "./Header";
import { initializeDeck, shuffleDeck /**getFakePlayers*/ } from "./Game-Helper";
import { PlayerGrid, IPlayerGridProps } from "./PlayerGrid";
import { EndGameState } from "Routes";

export function Game() {
  const { firestore, address } = React.useContext(UserContext);
  const [gameState, setGameState] = React.useState<IGameState>();
  const [roundState, setRoundState] = React.useState<IRoundState>();
  const [playerStateMap, setPlayerStateMap] =
    React.useState<Map<string, IPlayerState>>();
  const { gameId } = useParams<{ gameId: string }>();
  const [selectedCardId, setSelectedCardId] = React.useState<string>();
  const [selectedPlayer, setSelectedPlayer] = React.useState<string>();
  const [isInboxOpen, setIsInboxOpen] = React.useState<boolean>(false);
  const showGrid = true;

  const updateRoundState = React.useCallback(
    (roundNumber: number, roundState: Partial<IRoundState>) => {
      firestore
        .collection("games")
        .doc(gameId)
        .collection("rounds")
        .doc(roundNumber.toString())
        .update(roundState);
    },
    [firestore, gameId]
  );

  const updateGameState = React.useCallback(
    (gameState: Partial<IGameState>) => {
      firestore
        .collection("games")
        .doc(gameId)
        .update({
          ...gameState,
        });
    },
    [firestore, gameId]
  );

  const updatePlayerState = React.useCallback(
    (address: string, playerState: Partial<IPlayerState>) => {
      firestore
        .collection("games")
        .doc(gameId)
        .collection("players")
        .doc(address)
        .update(playerState);
    },
    [firestore, gameId]
  );

  const onInboxOpen = () => {
    setIsInboxOpen(true);
  };

  const onInboxClose = () => {
    setIsInboxOpen(false);
  };

  const isLoggedInPlayer = React.useCallback(
    (player: IPlayerState) => {
      return player.address === address;
    },
    [address]
  );

  const loggedInPlayer = React.useCallback(() => {
    return playerStateMap?.get(address);
  }, [playerStateMap, address]);

  const isLoggedInPlayerReady = () => {
    return loggedInPlayer()?.readyToStartGame;
  };

  const discardCard = React.useCallback(
    (playerState: Partial<IPlayerState>) => {
      const player = loggedInPlayer();
      if (player && selectedCardId && player.hand.length > 0) {
        const indexOfCard = player.hand.findIndex(
          (card) => card.id === selectedCardId
        );
        if (indexOfCard !== -1) {
          const card = player.hand.splice(indexOfCard, 1)[0];
          player.discardPile.push(card);
          updatePlayerState(address, {
            discardPile: player.discardPile,
            hand: player.hand,
            ...playerState,
          });
        }
      }
    },
    [address, loggedInPlayer, selectedCardId, updatePlayerState]
  );

  const listenForGameStateUpdate = React.useCallback(() => {
    firestore
      .collection("games")
      .doc(gameId)
      .onSnapshot((game) => {
        const gameData = game.data() as IGameState;
        if (gameData) {
          setGameState(gameData);
        }
      });
  }, [firestore, gameId]);

  React.useEffect(() => {
    listenForGameStateUpdate();
  }, [listenForGameStateUpdate]);

  const listenForRoundStateUpdate = React.useCallback(() => {
    firestore
      .collection("games")
      .doc(gameId)
      .collection("rounds")
      .doc(gameState?.round.toString())
      .onSnapshot((round) => {
        const roundData = round.data() as IRoundState;
        if (roundData) {
          if (roundData.status !== RoundStatus.notStarted) {
            firestore
              .collection("games")
              .doc(gameId)
              .collection("players")
              .doc(address)
              .get()
              .then((player) => {
                const playerData = player.data() as IPlayerState;
                if (playerData) {
                  if (
                    playerData.status === PlayStatus.waiting &&
                    roundData.status === RoundStatus.inProgress
                  ) {
                    player.ref.update({ status: PlayStatus.draw });
                  } else if (
                    roundData.status === RoundStatus.finished &&
                    playerData.status === PlayStatus.finished
                  ) {
                    // If there are no cards left in the deck and more than 1 card left in the players hand,
                    // player should continue to discard. Otherwise, player should be drawing cards.
                    const status =
                      playerData.deck.length === 0 && playerData.hand.length > 1
                        ? PlayStatus.discard
                        : PlayStatus.draw;
                    // discard the selected card (ie the played card)
                    discardCard({
                      status,
                    });
                    setSelectedPlayer(undefined);
                  }
                }
              });
          }
          setRoundState(roundData);
        }
      });
  }, [firestore, gameId, gameState, address, discardCard]);

  React.useEffect(() => {
    listenForRoundStateUpdate();
  }, [listenForRoundStateUpdate]);

  const listenForPlayerStateUpdate = React.useCallback(() => {
    firestore
      .collection("games")
      .doc(gameId)
      .collection("players")
      .onSnapshot((players) => {
        const map = new Map<string, IPlayerState>();
        players.forEach((player) => {
          const playerData = player.data() as IPlayerState;
          if (
            isLoggedInPlayer(playerData) &&
            playerData.deck.length === 0 &&
            playerData.hand.length === 0
          ) {
            const deck =
              playerData.discardPile.length > 0
                ? playerData.discardPile
                : initializeDeck();
            updatePlayerState(address, {
              deck: shuffleDeck(deck),
              discardPile: [],
            });
          }
          map.set(player.id, playerData);
        });
        setPlayerStateMap(map);
      });
  }, [firestore, gameId, address, isLoggedInPlayer, updatePlayerState]);

  React.useEffect(() => {
    listenForPlayerStateUpdate();
  }, [listenForPlayerStateUpdate]);

  const updatePlayerCoworkerField = (id: CoworkerId) => {
    const isPlayerInGame = playerStateMap?.has(address);
    if (isPlayerInGame) {
      updatePlayerState(address, { coworker: id });
    }
  };

  const updatePlayerReadyField = () => {
    updatePlayerState(address, { readyToStartGame: !isLoggedInPlayerReady() });
  };

  const areAllPlayersReady = () => {
    if (playerStateMap) {
      let allPlayersReady = true;
      playerStateMap.forEach((player) => {
        if (!player.readyToStartGame) {
          allPlayersReady = false;
        }
      });
      return allPlayersReady;
    }
    return false;
  };

  const startGame = () => {
    updateGameState({ status: GameStatus.inProgress });
    updateRoundState(0, { status: RoundStatus.inProgress });
  };

  const drawCard = () => {
    const player = loggedInPlayer();
    if (player && player.deck.length > 0) {
      if (player.hand.length < 4) {
        const card = player.deck.splice(0, 1)[0];
        player.hand.push(card);
        updatePlayerState(address, { hand: player.hand, deck: player.deck });
      }
      if (player.hand.length === 4) {
        updatePlayerState(address, { status: PlayStatus.discard });
      }
    }
  };

  const onDiscardClick = () => {
    discardCard({ status: PlayStatus.play });
  };

  const playCard = () => {
    const player = loggedInPlayer();
    if (
      player &&
      selectedPlayer &&
      selectedCardId &&
      roundState?.round !== undefined
    ) {
      const move: IMove = {
        source: player.address,
        target: selectedPlayer,
        card: getCard(player, selectedCardId),
      };

      const targetRef = firestore
        .collection("games")
        .doc(gameId)
        .collection("players")
        .doc(selectedPlayer);
      const playerRef = firestore
        .collection("games")
        .doc(gameId)
        .collection("players")
        .doc(address);

      let targetMap: TargetMap;

      firestore.runTransaction((transaction) => {
        return transaction
          .get(targetRef)
          .then((targetDoc) => {
            targetMap = targetDoc.data()?.targetMap;
            if (move.card) {
              if (targetMap) {
                let moves = targetMap[move.card.cardType];
                if (moves) {
                  moves.push(move);
                } else {
                  moves = [move];
                }
                targetMap[move.card.cardType] = moves;
              } else {
                targetMap = { [move.card.cardType]: [move] };
              }
            }
            transaction.update(targetRef, { targetMap }).update(playerRef, {
              status: PlayStatus.finished,
              move,
            });
          })
          .catch((error) => {
            console.log("Transaction failed: ", error);
          });
      });
    }
  };

  const getCard = (
    player?: IPlayerState,
    cardId?: string
  ): ICardProps | undefined => {
    if (!player || !cardId) {
      return undefined;
    }
    const card = player.hand.find((card) => card.id === cardId);
    return card;
  };

  const onSetSelectedCardId = (cardId: string | undefined) => {
    const player = loggedInPlayer();
    if (
      player &&
      player.status !== PlayStatus.waiting &&
      player.status !== PlayStatus.finished
    ) {
      setSelectedCardId(cardId);
    }
  };

  const playerGridProps: IPlayerGridProps = {
    gameState,
    playerStateMap,
    loggedInPlayer,
    setSelectedPlayer,
    selectedPlayer,
    address,
    isLoggedInPlayer,
    updatePlayerCoworkerField,
    isLoggedInPlayerReady,
    updatePlayerReadyField,
    selectedCard: getCard(loggedInPlayer(), selectedCardId),
  };

  return (
    <div className="App-Page">
      <div
        className={
          loggedInPlayer()?.endGameState === EndGameState.eliminated ||
          gameState?.status === GameStatus.finished
            ? "Grid-Finished"
            : "Grid"
        }
      >
        <div className={`Grid__Inbox ${showGrid && "Show-Grid"}`}>
          <>
            <InboxButton onClick={onInboxOpen} roundState={roundState} />
            {playerStateMap && (
              <Inbox
                open={isInboxOpen}
                onClose={onInboxClose}
                gameId={gameId}
                playerStateMap={playerStateMap}
              />
            )}
          </>
        </div>
        <div className={`Grid__Header ${showGrid && "Show-Grid"}`}>
          <Header
            gameState={gameState}
            startGame={startGame}
            address={address}
            areAllPlayersReady={areAllPlayersReady}
            roundState={roundState}
          />
        </div>
        <div className={`Grid__Timer ${showGrid && "Show-Grid"}`}>
          {roundState && gameState?.status !== GameStatus.finished && (
            <Timer roundState={roundState} gameId={gameId} />
          )}
        </div>
        <div className={`Grid__Main ${showGrid && "Show-Grid"}`}>
          <PlayerGrid {...playerGridProps} />
          {/* {getFakePlayers()} */}
        </div>
        <div className={`Grid__Sidebar ${showGrid && "Show-Grid"}`}>
          <div className="Chat">Werk Chat</div>
        </div>
        {loggedInPlayer()?.endGameState === EndGameState.won ? (
          <div className="Grid__Status">You Won</div>
        ) : loggedInPlayer()?.endGameState === EndGameState.eliminated ? (
          <div className="Grid__Status">Fired</div>
        ) : (
          <>
            <div className={`Grid__Discard-Pile ${showGrid && "Show-Grid"}`}>
              <div>Discard Pile</div>
              <DiscardPile cards={loggedInPlayer()?.discardPile} />
            </div>
            <div className={`Grid__Hand ${showGrid && "Show-Grid"}`}>
              <Hand
                loggedInPlayer={loggedInPlayer()}
                selectedCardId={
                  loggedInPlayer()?.move?.card?.id || selectedCardId
                }
                setSelectedCardId={onSetSelectedCardId}
                selectedPlayer={selectedPlayer}
                address={address}
              />
            </div>
            <div className={`Grid__Hand-Actions ${showGrid && "Show-Grid"}`}>
              <button
                onClick={drawCard}
                className="Game-Button Grid__Hand-Actions__Button"
                disabled={loggedInPlayer()?.status !== PlayStatus.draw}
              >
                Draw
              </button>
              <button
                onClick={onDiscardClick}
                className="Game-Button Grid__Hand-Actions__Button"
                disabled={loggedInPlayer()?.status !== PlayStatus.discard}
              >
                Discard
              </button>
              <button
                onClick={playCard}
                className="Game-Button Grid__Hand-Actions__Button"
                disabled={loggedInPlayer()?.status !== PlayStatus.play}
              >
                Play
              </button>
            </div>
            <div className={`Grid__Deck ${showGrid && "Show-Grid"}`}>
              <div>Deck</div>
              <Deck cards={loggedInPlayer()?.deck} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import React from "react";
import "./HowTo.css";

export function HowTo() {
  return (
    <div className="App-Page">
      <div className="HowTo__text">
        <h1>Cowerkerz</h1>
        <h2>What is it?</h2>
        <p>
          Cowerkerz is a Battle Royale Prisoners Dilemma NFT Card Game based on
          the most fulfilling experience in life...Work.
        </p>
        <h2>Gameplay</h2>
        <ul>
          <li>Number of Players: 4-8</li>
          <li>
            Each player starts the game with:
            <ul>
              <li>A deck of 20 cards.</li>
              <li>4 of those cards in their hand.</li>
              <li>10 health points.</li>
            </ul>
          </li>
          <li>Game is played in rounds.</li>
          <li>
            Each round can be timed (recommended) or not timed. Preference is
            set by game host.
          </li>
          <li>
            All players perform their actions within the round at the same time.
          </li>
          <li>
            Once the round begins, each player must discard 1 card, then play 1
            card.
          </li>
          <li>
            Depending on the card, the card can be played on themselves or on
            another player. Cards can do a variety of things like attack another
            player, prevent another player from attacking, etc. The list of
            cards and their functionality is explained in more detail in the
            Cards section.
          </li>
          <li>
            Players can't see what cards other players have played, but they can
            see an indicator showing a player has played a card.
          </li>
          <li>
            After each player has played a card, the cards are revealed, the
            results of the round are calculated, and the round ends.
          </li>
          <li>
            When the next round begins, players draw cards from their deck until
            they have 4 cards again.
          </li>
          <li>
            When all cards have been drawn, cards in the discard pile are
            shuffled and placed back in the draw deck.
          </li>
          <li>
            The game ends when only 2 (or less) players have health remaining.
            The remaining players are declared the winners &#x1F600;
          </li>
        </ul>
        <h2>Cards</h2>
        <p>
          A deck is made up of 20 cards. Cards can belong to 1 of 7 categories:
        </p>
        <ul>
          <li className="CardItem">
            <strong>Attack One</strong> - 6 cards
            <ul>
              <li>-1 to target.</li>
              <li>
                If target is self healing, being healed by someone else, or
                being prevented from taking an action, -2 to target.
              </li>
            </ul>
          </li>
          <li className="CardItem">
            <strong>Attack All</strong> - 2 cards
            <ul>
              <li>-3 to everyone except yourself.</li>
              <li>
                If more than 1 player attacks all, -3 to only the players
                performing attack all.
              </li>
            </ul>
          </li>
          <li className="CardItem">
            <strong>Group Attack</strong> - 3 cards
            <ul>
              <li>
                Target receives -2*P damage for each player (P) participating in
                the group attack.
              </li>
              <li>
                Otherwise, target receives 0 damage if only 1 player
                participates in the group attack.
              </li>
            </ul>
          </li>
          <li className="CardItem">
            <strong>Heal Self</strong> - 3 cards
            <ul>
              <li>+2 to yourself.</li>
              <li>-2 to yourself if someone attacks you.</li>
            </ul>
          </li>
          <li className="CardItem">
            <strong>Heal Other</strong> - 1 card
            <ul>
              <li>+2 to target. </li>
              <li>-2 to target if someone is attacking them.</li>
            </ul>
          </li>
          <li className="CardItem">
            <strong>Prevent Action</strong> - 3 cards
            <ul>
              <li>Prevent player from playing their card. </li>
              <li>
                However, a prevent action card cannot prevent another prevent
                action card.
              </li>
            </ul>
          </li>
          <li className="CardItem">
            <strong>Evade</strong> - 2 cards
            <ul>
              <li>Evade actions in which you're a target.</li>
              <li>However, you cannot evade group attacks.</li>
              <li>
                Evading 2 times in a row without getting attacked results in a
                penalty (-1 damage to yourself).
              </li>
              <li>
                The penalty counter is reset when a successful evade is
                executed.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

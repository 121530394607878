import React from "react";
import { ICardProps } from "Components/Card/Card";
import "./DiscardPile.css";

interface IDiscardPileProps {
  cards?: ICardProps[];
}

export function DiscardPile(props: IDiscardPileProps) {
  const { cards } = props;
  return (
    <div className="Discard-Pile">
      <div className="Discard-Pile__Card-Count">{cards?.length}</div>
    </div>
  );
}

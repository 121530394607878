import React from "react";
import { Card, CardType } from "Components/Card/Card";
import "./Hand.css";
import { IPlayerState, PlayStatus } from "Routes";

interface IHandProps {
  loggedInPlayer?: IPlayerState;
  setSelectedCardId: (cardId: string | undefined) => void;
  selectedCardId?: string;
  selectedPlayer?: string;
  address?: string;
}

export function Hand(props: IHandProps) {
  const {
    loggedInPlayer,
    selectedCardId,
    setSelectedCardId,
    selectedPlayer,
    address,
  } = props;
  const cards = loggedInPlayer?.hand;
  const playStatus = loggedInPlayer?.status;
  const previousSelectedPlayer = React.useRef<string>();

  React.useEffect(() => {
    // If a player was previously selected and the selected player has changed, clear the selected card.
    // A player should rechoose what move they want to do when switching targets.
    if (
      previousSelectedPlayer.current &&
      previousSelectedPlayer.current !== selectedPlayer
    ) {
      setSelectedCardId(undefined);
    }
    previousSelectedPlayer.current = selectedPlayer;
  }, [selectedPlayer, setSelectedCardId]);

  return (
    <div className={"Hand"}>
      {cards?.map((card) => {
        const onCardClick = () => {
          let isSelectable =
            playStatus === PlayStatus.discard || !selectedPlayer || false;
          if (address !== selectedPlayer) {
            if (
              [
                CardType.attackOne,
                CardType.groupAttack,
                CardType.healOther,
                CardType.preventAction,
              ].includes(card.cardType)
            ) {
              isSelectable = true;
            }
          }
          if (address === selectedPlayer) {
            if (
              [CardType.attackAll, CardType.evade, CardType.healSelf, CardType.work].includes(
                card.cardType
              )
            ) {
              isSelectable = true;
            }
          }
          if (isSelectable && selectedCardId !== card.id) {
            setSelectedCardId(card.id);
          } else {
            setSelectedCardId(undefined);
          }
        };
        return (
          <Card
            {...card}
            onCardClick={onCardClick}
            selectedCardId={selectedCardId}
            key={card.id}
          />
        );
      })}
    </div>
  );
}

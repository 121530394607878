import React from "react";
import { ICardProps } from "Components/Card/Card";
import "./Deck.css";

interface IDeckProps {
  cards?: ICardProps[];
}

export function Deck(props: IDeckProps) {
  const { cards } = props;
  return (
    <div className="Deck">
      <div className="Deck__Card-Count">{cards?.length}</div>
    </div>
  );
}

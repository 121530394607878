import React from "react";
import { ICardProps, CardType, CoworkerSelector } from "Components";

export function shuffleDeck(deck: ICardProps[]): ICardProps[] {
  for (let i = deck.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = deck[i];
    deck[i] = deck[j];
    deck[j] = temp;
  }
  return deck;
}

export function initializeDeck(): ICardProps[] {
  const deck: ICardProps[] = [];
  const cards = [
    {
      cardType: CardType.attackOne,
      description: "Slap",
      info: "-1 to target. If target is eating lunch, given a donut, or being distracted, -2 to target.",
      report: "[source] slapped [target].",
      count: 6,
    },
    {
      cardType: CardType.attackAll,
      description: "Report everyone to HR",
      info: "-3 to everyone except yourself. If more than 1 player reports to HR, -3 to only the players reporting to HR.",
      report: "[source] reported everyone to HR.",
      count: 2,
    },
    {
      cardType: CardType.groupAttack,
      description: "Silent Treatment",
      info: "Target receives -2*P damage for each player (P) participating in the silent treatment.",
      report: "[source] gave [target] the silent treatment.",
      count: 3,
    },
    {
      cardType: CardType.healSelf,
      description: "Eat lunch",
      info: "+2 to yourself. -2 to yourself if someone slaps you.",
      report: "[source] ate lunch.",
      count: 3,
    },
    {
      cardType: CardType.healOther,
      description: "Give donut",
      info: "+2 to target. -2 to target if someone is slapping them.",
      report: "[source] gave [target] a donut.",
      count: 1,
    },
    {
      cardType: CardType.preventAction,
      description: "Distract",
      info: "Stop player from playing their card. However, a distract card cannot stop another distract card. -2 to target if someone is slapping them.",
      report: "[source] distracted [target].",
      count: 3,
    },
    {
      cardType: CardType.evade,
      description: "Hide",
      info: "Evade actions in which you're a target. However, you cannot evade a successful silent treatment. Hiding 2 times in a row without getting attacked results in a penalty (-1 damage to yourself).",
      report: "[source] hid in the bathroom.",
      count: 2,
    },
    {
      cardType: CardType.work,
      description: "Work",
      info: "Work a total of 40 hours to win the game.",
      report: "[source] worked.",
      count: 6,
    },
  ];

  let hours = 4;
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    for (let k = 0; k < card.count; k++) {
      if (card.cardType === CardType.work) {
        deck.push({
          id: `${card.cardType}${hours}`,
          cardType: card.cardType,
          description: `Work ${hours} hours.`,
          info: "Work a total of 40 hours to win the game.",
          report: `[source] worked ${hours} hours.`,
        });
        hours += 2;
      } else {
        deck.push({
          id: `${card.cardType}${k}`,
          cardType: card.cardType,
          description: card.description,
          info: card.info,
          report: card.report,
        });
      }
    }
  }
  return deck;
}

export function getFakePlayers() {
  const fakePlayers = [];
  for (let i = 0; i < 8; i++) {
    fakePlayers.push(i);
  }
  return fakePlayers.map((player) => (
    <div className="Grid__Main__Player">
      {player}
      <CoworkerSelector changeSelection={() => { }} isSelectionEnabled={true} />
    </div>
  ));
}


import React from "react";
import "./Inbox.css";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { UserContext } from "UserContext";
import { IRoundState, IPlayerState } from "Routes";
import { InboxItem } from "./InboxItem";
import { InboxMain } from "./InboxMain";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IInboxProps {
  open: boolean;
  onClose: () => void;
  gameId: string;
  playerStateMap: Map<string, IPlayerState>;
}

export function Inbox(props: IInboxProps) {
  const { open, onClose, gameId, playerStateMap } = props;
  const { firestore } = React.useContext(UserContext);
  const [rounds, setRounds] = React.useState<IRoundState[]>();
  const [selectedRound, setSelectedRound] = React.useState<number>(0);

  React.useEffect(() => {
    firestore
      .collection("games")
      .doc(gameId)
      .collection("rounds")
      .onSnapshot((rounds) => {
        setRounds(
          rounds.docs
            .map((doc) => doc.data())
            .sort((data1, data2) => data1.round - data2.round) as IRoundState[]
        );
        const lastRound = rounds.size === 1 ? 0 : rounds.size - 2;
        setSelectedRound(lastRound);
      });
  }, [firestore, gameId]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth={"xl"}
      fullWidth={true}
    >
      <div className="Inbox">
        <div className="Inbox__Header">Inbox</div>
        <div className="Inbox__Left-Pane">
          {rounds?.map((round) => {
            const onRoundClick = () => {
              setSelectedRound(round.round);
            };
            return rounds.length - 1 !== round.round ? (
              <InboxItem
                round={round}
                onRoundClick={onRoundClick}
                selectedRound={selectedRound}
              />
            ) : undefined;
          })}
        </div>
        <div className="Inbox__Main">
          {rounds && (
            <InboxMain
              round={rounds[selectedRound]}
              playerStateMap={playerStateMap}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}

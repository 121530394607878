import React from "react";
import "./Dashboard.css";
import { UserContext } from "UserContext";
import { Edit, Done } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
// import { useHistory, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  IGameState,
  IPlayerState,
  GameStatus,
  IRoundState,
  PlayStatus,
  RoundStatus,
  DayOfTheWeek,
  EndGameState,
} from "Routes";
import firebase from "firebase";

// function useQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

export function Dashboard() {
  const { address, firestore, playerName, setPlayerName } =
    React.useContext(UserContext);
  const [showChangeNameInputBox, setShowChangeNameInputBox] =
    React.useState(false);
  const nameInput = React.useRef<HTMLInputElement>(null);
  const joinGameInput = React.useRef<HTMLInputElement>(null);
  let history = useHistory();
  // const query = useQuery();
  // const isGuest = query.get("guest");

  React.useEffect(() => {
    if (nameInput.current) {
      nameInput.current.focus();
    }
  });

  const setNameInDB = React.useCallback(
    async (address: string, name: string) => {
      const playersCollection = firestore.collection("players");
      try {
        await playersCollection.doc(address).set({
          name,
        });
        setPlayerName(name);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },
    [firestore, setPlayerName]
  );

  const showInput = () => {
    setShowChangeNameInputBox(true);
  };

  const showName = () => {
    setShowChangeNameInputBox(false);
  };

  const joinGame = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const gameId = joinGameInput.current?.value;
    if (gameId) {
      const gamesDoc = firestore.collection("games").doc(gameId);
      try {
        const game = await gamesDoc.get();
        if (game.exists) {
          // If the player has already joined game, route them to game without updating db.
          // Otherwise, add them to game.
          const playerDoc = gamesDoc.collection("players").doc(address);
          const player = await playerDoc.get();
          if (!player.exists) {
            const player: IPlayerState = {
              address,
              name: playerName,
              readyToStartGame: false,
              deck: [],
              hand: [],
              discardPile: [],
              status: PlayStatus.waiting,
              targetMap: {},
              health: 10,
              evadeCount: 0,
              hours: 0,
              endGameState: EndGameState.playing,
            };
            gamesDoc.update({
              playerPositions:
                firebase.firestore.FieldValue.arrayUnion(address),
            });
            playerDoc.set(player);
          }
          history.push(`/game/${gameId}`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeName = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setNameInDB(address, nameInput.current?.value || "");
    showName();
  };

  const createGameId = (length: number) => {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const createGame = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const gameId = createGameId(7);
    const gamesCollection = firestore.collection("games");
    const gameState: IGameState = {
      id: gameId,
      host: address,
      status: GameStatus.notStarted,
      playerPositions: [address],
      round: 0,
    };
    try {
      const gamesDoc = gamesCollection.doc(gameId);
      await gamesDoc.set(gameState);
      const player: IPlayerState = {
        address,
        name: playerName,
        readyToStartGame: false,
        deck: [],
        hand: [],
        discardPile: [],
        status: PlayStatus.waiting,
        targetMap: {},
        health: 10,
        evadeCount: 0,
        hours: 0,
        endGameState: EndGameState.playing,
      };
      await gamesDoc.collection("players").doc(address).set(player);
      const round: IRoundState = {
        round: 0,
        status: RoundStatus.notStarted,
        day: DayOfTheWeek.Monday,
        roundExpiredCount: 0,
      };
      await gamesDoc.collection("rounds").doc("0").set(round);
      history.push(`/game/${gameId}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App-Page">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="Welcome-Text">
          Hi,{" "}
          {showChangeNameInputBox ? (
            <form onSubmit={changeName}>
              <input
                ref={nameInput}
                className="Dashboard-Input Name-Input"
                maxLength={30}
              ></input>
            </form>
          ) : (
            playerName || address
          )}
        </div>
        {showChangeNameInputBox ? (
          <Tooltip title="Confirm Name Change">
            <div className="Dashboard-Icon" onClick={changeName}>
              <Done fontSize={"large"} />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Change Name">
            <div className="Dashboard-Icon" onClick={showInput}>
              <Edit fontSize={"large"} />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="App-Link Create-Game-Link" onClick={createGame}>
        Create Game
      </div>
      <div style={{ fontWeight: 700, margin: "2rem 0" }}>Or</div>
      <div className="Join-Game">
        Game Code:{" "}
        <form onSubmit={joinGame}>
          <input
            ref={joinGameInput}
            className="Dashboard-Input Join-Game-Input"
          ></input>
        </form>
        <button onClick={joinGame} className="Join-Button">
          Join
        </button>
      </div>
    </div>
  );
}

import React from "react";
import { Help } from "@material-ui/icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Card.css";

export type ICardProps = {
  id: string;
  cardType: CardType;
  description: string;
  info: string;
  report: string;
  onCardClick?: () => void;
  selectedCardId?: string;
};

export enum CardType {
  attackOne = "attackOne",
  attackAll = "attackAll",
  groupAttack = "groupAttack",
  preventAction = "preventAction",
  healSelf = "healSelf",
  healOther = "healOther",
  evade = "evade",
  work = "work",
}

export function Card(props: ICardProps) {
  const { id, description, onCardClick, selectedCardId, info } = props;
  const [showHover, setShowHover] = React.useState<boolean>(false);
  let cardClassName = id === selectedCardId ? "Card Card__Selected" : "Card";
  if (showHover) {
    cardClassName += " Card__Hovered";
  }

  const handleMouseEnter = () => {
    setShowHover(true);
  };

  const handleMouseLeave = () => {
    setShowHover(false);
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>{info}</div>
    </Tooltip>
  );

  return (
    <button
      onClick={onCardClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cardClassName}
    >
      <div className={"Card__Description"}>{description}</div>
      <div className="Card__Type">
        <OverlayTrigger placement="right" overlay={renderTooltip}>
          <Help className="Card__Type__HelpIcon" />
        </OverlayTrigger>
      </div>
    </button>
  );
}

import React from "react";
import "./InboxButton.css";
import { Email } from "@material-ui/icons";
import { IRoundState, RoundStatus } from "Routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface IInboxButtonProps {
  onClick: () => void;
  roundState?: IRoundState;
}

export function InboxButton(props: IInboxButtonProps) {
  const { onClick, roundState } = props;
  const [showInboxTooltip, setShowInboxTooltip] = React.useState(false);
  const roundShownInTooltip = React.useRef(0);

  React.useEffect(() => {
    const roundFinished = roundState?.status === RoundStatus.finished;
    if (!showInboxTooltip && roundState && roundFinished) {
      roundShownInTooltip.current = roundState.round;
      setShowInboxTooltip(true);
    }
    const timer = setTimeout(() => {
      setShowInboxTooltip(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, [roundState, showInboxTooltip]);

  const onInboxButtonClick = () => {
    onClick();
    setShowInboxTooltip(false);
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" className="Inbox-Button-Tooltip" {...props}>
      {`Round ${roundShownInTooltip.current} finished. Check Inbox for results.`}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={renderTooltip}
      show={showInboxTooltip}
    >
      <button onClick={onInboxButtonClick} className="Inbox-Button">
        <Email className="Email-Icon" fontSize={"large"} />
        <div>Inbox</div>
      </button>
    </OverlayTrigger>
  );
}

import React from "react";
import { IRoundState } from "Routes";
import { UserContext } from "UserContext";
import "./Timer.css";

const ONE_SECOND = 1000;

interface ITimerProps {
  roundState: IRoundState;
  gameId: string;
}

const calculateTimeLeftInSeconds = (roundEndTimestamp?: number): number => {
  if (!roundEndTimestamp) {
    return 0;
  }
  return Math.floor((roundEndTimestamp - Date.now()) / ONE_SECOND);
};

export function Timer(props: ITimerProps) {
  const {
    roundState: { roundEndTimestamp, round, status },
    gameId,
  } = props;
  const { firestore, address } = React.useContext(UserContext);
  const [timeLeft, setTimeLeft] = React.useState(0);
  const sentRoundExpiredNotice = React.useRef(false);
  const roundNumberRef = React.useRef(round);

  React.useEffect(() => {
    const timeLeftInSeconds = calculateTimeLeftInSeconds(roundEndTimestamp);
    if (timeLeftInSeconds >= 0) {
      sentRoundExpiredNotice.current = false;
      setTimeout(() => {
        setTimeLeft(timeLeftInSeconds);
      }, ONE_SECOND);
    } else if (
      round === roundNumberRef.current &&
      !sentRoundExpiredNotice.current
    ) {
      sentRoundExpiredNotice.current = true;
      const roundRef = firestore
        .collection("games")
        .doc(gameId)
        .collection("rounds")
        .doc(round.toString());
      firestore.runTransaction((transaction) => {
        return transaction
          .get(roundRef)
          .then((doc) => {
            const currentRoundState = doc.data() as IRoundState;
            if (currentRoundState.roundExpiredCount === 0) {
              return transaction.update(roundRef, {
                roundExpiredCount: currentRoundState.roundExpiredCount + 1,
              });
            } else {
              return Promise.reject();
            }
          })
          .catch((error) => {
            console.log("Transaction failed: ", error);
          });
      });
    }
    if (round !== roundNumberRef.current) {
      roundNumberRef.current = round;
    }
  }, [timeLeft, roundEndTimestamp, address, firestore, gameId, status, round]);

  return <div className="Timer">{timeLeft}</div>;
}

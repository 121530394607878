import React from "react";
import "./WorkStatus.css";
import { IPlayerState } from "Routes";

interface IWorkStatusProps {
  playerState: IPlayerState;
}

export function WorkStatus(props: IWorkStatusProps) {
  const {
    playerState: { hours },
  } = props;

  let workStatus = `Hours Worked: ${hours}/40`;

  return <div className={"WorkStatus"}>{workStatus}</div>;
}

import React from "react";
import {
  PurpleCoworker,
  BlueCoworker,
  GreenCoworker,
  YellowCoworker,
  RedCoworker,
} from "Assets";

export enum CoworkerId {
  red = "RED",
  blue = "BLUE",
  green = "GREEN",
  yellow = "YELLOW",
  purple = "PURPLE",
}

interface ICoworkerProps {
  id: CoworkerId;
}

const coworkers = new Map([
  [CoworkerId.purple, <img src={PurpleCoworker} alt={"Coworker"} />],
  [CoworkerId.blue, <img src={BlueCoworker} alt={"Coworker"} />],
  [CoworkerId.green, <img src={GreenCoworker} alt={"Coworker"} />],
  [CoworkerId.yellow, <img src={YellowCoworker} alt={"Coworker"} />],
  [CoworkerId.red, <img src={RedCoworker} alt={"Coworker"} />],
]);

export function Coworker(props: ICoworkerProps) {
  const { id } = props;
  return coworkers.get(id) || null;
}

import { CoworkerId, ICardProps, CardType } from "Components";

export enum GameStatus {
  notStarted = "Not Started",
  inProgress = "Game In Progress",
  finished = "Finished",
}

export enum PlayStatus {
  draw = "Draw",
  discard = "Discard",
  play = "Play",
  waiting = "Waiting",
  finished = "Finished",
}

export enum EndGameState {
  playing = "Playing",
  won = "Won",
  eliminated = "Eliminated",
}

export enum RoundStatus {
  notStarted = "Not Started",
  inProgress = "Round In Progress",
  finished = "Finished",
}

export interface IMove {
  source: string;
  target: string;
  card: ICardProps | undefined;
}

export interface IRoundState {
  round: number;
  status: RoundStatus;
  day: DayOfTheWeek;
  report?: any[];
  roundEndTimestamp?: number;
  roundExpiredCount: number;
}

export enum DayOfTheWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export interface IGameState {
  id: string;
  host: string;
  status: GameStatus;
  playerPositions: string[];
  round: number;
}

export interface IPlayerState {
  address: string;
  name: string;
  readyToStartGame: boolean;
  coworker?: CoworkerId;
  deck: ICardProps[];
  hand: ICardProps[];
  discardPile: ICardProps[];
  status: PlayStatus;
  endGameState: EndGameState;
  targetMap: TargetMap;
  health: number;
  move?: IMove;
  evadeCount: number;
  hours: number;
}

export type TargetMap = {
  [CardType.attackOne]?: IMove[];
  [CardType.attackAll]?: IMove[];
  [CardType.groupAttack]?: IMove[];
  [CardType.healSelf]?: IMove[];
  [CardType.healOther]?: IMove[];
  [CardType.evade]?: IMove[];
  [CardType.preventAction]?: IMove[];
  [CardType.work]?: IMove[];
};

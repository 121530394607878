import React from "react";
import { IRoundState, IPlayerState, RoundStatus } from "Routes";
import "./InboxMain.css";

interface IInboxMainProps {
  round: IRoundState;
  playerStateMap: Map<string, IPlayerState>;
}

export function InboxMain(props: IInboxMainProps) {
  const { round, playerStateMap } = props;

  const getName = (
    playerStateMap: Map<string, IPlayerState>,
    player: string
  ) => {
    const state = playerStateMap.get(player);
    return (state?.name || state?.address)?.slice(0, 15);
  };

  const toLine =
    round.report &&
    round.report
      .filter((report) => report.source !== undefined)
      .map(
        (report) => `${getName(playerStateMap, report.source)}@cowerkerz.com`
      )
      .join("; ");

  const movesReport =
    round.report &&
    round.report
      .filter((report) => report.type === "move")
      .map((report) => (
        <li key={report.source}>
          {report.card.report.replace(
            /\[source\]|\[target\]/g,
            (match: string) => {
              return getName(
                playerStateMap,
                match === "[source]" ? report.source : report.target
              );
            }
          )}
        </li>
      ));

  const healthReport =
    round.report &&
    round.report
      .filter((report) => report.type === "health")
      .map((report) => (
        <li key={`${report.address}${report.healthChange}`}>{`Player:${getName(
          playerStateMap,
          report.address
        )}, Previous Health: ${report.oldHealth}, Current Health: ${
          report.newHealth
        }`}</li>
      ));

  const hoursReport =
    round.report &&
    round.report
      .filter((report) => report.type === "hours")
      .map((report) => (
        <li key={`${report.address}${report.hoursChange}`}>{`Player:${getName(
          playerStateMap,
          report.address
        )}, Previous Hours: ${report.oldHours}, Current Hours: ${
          report.newHours
        }`}</li>
      ));

  const getEvadeMessage = (name?: string, message?: string): string => {
    if (message === "increaseEvadeCount") {
      return `${name} hid in the bathroom without being attacked. If this happens again, they'll receive a penalty.`;
    } else if (message === "resetEvadeCount") {
      return `${name} successfully avoided damage by hiding in the bathroom. Penalty counter has been reset.`;
    } else if (message === "evadePenalty") {
      return `${name} hid in the bathroom without being attacked (again). ${name} has incurred a penalty.`;
    }
    return "";
  };

  const slackerReport =
    round.report &&
    round.report
      .filter((report) => report.type === "evade")
      .map((report) => (
        <li key={`${report.type}${report.address}`}>
          {report.type === "evade" &&
            getEvadeMessage(
              getName(playerStateMap, report.address),
              report.message
            )}
        </li>
      ));

  const roundExpiredReport =
    round.report &&
    round.report
      .filter((report) => report.type === "expired")
      .map((report) => (
        <li key={`${report.type}${report.address}`}>
          {report.type === "expired" &&
            `${getName(
              playerStateMap,
              report.address
            )} failed to make a move before EOD. -4 Morale.`}
        </li>
      ));

  return round.round === 0 && round.status !== RoundStatus.finished ? (
    <div className="Inbox-Empty">Inbox is Empty</div>
  ) : (
    <>
      <div className="Inbox-Main__Header">
        <div className="Inbox-Main__Header__Subject">{`Round ${round.round} Report`}</div>
        <div className="Inbox-Main__Header__From">
          from: inhuman-resources@cowerkerz.com
        </div>
        <div className="Inbox-Main__Header__To">to: {toLine}</div>
      </div>
      <div className="Inbox-Main__Body">
        <ul>
          <div className="Inbox-Main__Body__Title">Moves:</div>
          {movesReport}
        </ul>
        <ul>
          <div className="Inbox-Main__Body__Title">Health Updates:</div>
          {healthReport}
        </ul>
        <ul>
          <div className="Inbox-Main__Body__Title">Timesheet Updates:</div>
          {hoursReport}
        </ul>
        {slackerReport && slackerReport.length ? (
          <ul>
            <div className="Inbox-Main__Body__Title">Slacker Updates:</div>
            {slackerReport}
          </ul>
        ) : null}
        {roundExpiredReport && roundExpiredReport.length ? (
          <ul>
            <div className="Inbox-Main__Body__Title">Round Expired Report:</div>
            {roundExpiredReport}
          </ul>
        ) : null}
      </div>
    </>
  );
}

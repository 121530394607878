import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { LandingPage, Dashboard, HowTo, Game } from "Routes";
import { UserContext, user } from "UserContext";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { v4 as uuid } from "uuid";

export default function App() {
  const [address, setAddress] = React.useState<string>();
  const [playerName, setPlayerName] = React.useState<string>();
  const [guestId, setGuestId] = React.useState(
    localStorage.getItem("guestId") || ""
  );

  React.useEffect(() => {
    if (guestId === "") {
      const newGuestId = uuid();
      setGuestId(newGuestId);
      localStorage.setItem("guestId", newGuestId);
    }
  }, [guestId]);

  React.useEffect(() => {
    getPlayerName();
  });

  const getPlayerName = () => {
    // Check if player name exists in db. If it does, set that as the player name on client
    if (address || guestId) {
      user.firestore
        .collection("players")
        .doc(address || guestId)
        .onSnapshot(
          (player) => {
            if (player.exists) {
              setPlayerName(player.data()?.["name"]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const connectMetamask = React.useCallback(async () => {
    const ethereum = (window as any).ethereum;
    if (ethereum) {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        setAddress(account);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const listenForAccountChange = React.useCallback(() => {
    const ethereum = (window as any).ethereum;

    const setNewAddress = async (addresses: string[]) => {
      setAddress(addresses[0]);
    };

    if (ethereum) {
      ethereum.on("accountsChanged", setNewAddress);
    }

    return () => {
      if (ethereum) {
        ethereum.removeListener("accountsChanged", setNewAddress);
      }
    };
  }, []);

  React.useEffect(() => {
    listenForAccountChange();
  }, [listenForAccountChange]);

  React.useEffect(() => {
    connectMetamask();
  }, [connectMetamask]);

  return (
    <UserContext.Provider
      value={{
        ...user,
        address: address || guestId,
        connectMetamask,
        playerName: playerName || "",
        setPlayerName,
      }}
    >
      <Router>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/howto">
            <HowTo />
          </Route>
          <Route path={"/game/:gameId"}>
            <Game />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

import React from "react";
import { IRoundState } from "Routes";
import "./InboxItem.css";

interface IInboxItemProps {
  round: IRoundState;
  onRoundClick: () => void;
  selectedRound: number;
}

export function InboxItem(props: IInboxItemProps) {
  const { round, onRoundClick, selectedRound } = props;
  return (
    <div
      key={round.round}
      onClick={onRoundClick}
      className={`Inbox-Item ${
        round.round === selectedRound ? "Inbox-Item__Selected" : ""
      }`}
    >
      <div className="Inbox-Item__Subject">Round {round.round} Report</div>
      <div className="Inbox-Item__Sender">inhuman-resources@cowerkerz.com</div>
    </div>
  );
}

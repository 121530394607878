import React from "react";
import { Link } from "react-router-dom";
import Spritesheet from "react-responsive-spritesheet";
import { CoworkerLandingPageSpriteSheet } from "Assets";
import MetaMaskOnboarding from "@metamask/onboarding";
import "./LandingPage.css";
import { UserContext } from "UserContext";

export function LandingPage() {
  const onboarding = React.useRef<MetaMaskOnboarding>();
  const { address, connectMetamask } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  const installMetamask = () => {
    onboarding.current?.startOnboarding();
  };

  const getLink = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (address) {
        onboarding.current?.stopOnboarding();
        return (
          <Link
            className="App-Link"
            to={{
              pathname: "/dashboard",
            }}
          >
            Play with crypto wallet
          </Link>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            To play for crypto,
            <div
              className="App-Link"
              style={{ paddingLeft: ".6rem" }}
              onClick={connectMetamask}
            >
              Connect to Metamask
            </div>
          </div>
        );
      }
    } else {
      return (
        <div style={{ display: "flex" }}>
          To play for crypto,
          <div
            style={{ paddingLeft: ".6rem" }}
            className="App-Link"
            onClick={installMetamask}
          >
            install Metamask extension
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="Landing-Page-Header">
        <div style={{ marginRight: "3rem", marginTop: "1rem" }}>
          <Link className="App-Link" to={{ pathname: "/howto" }}>
            tf is this?
          </Link>
        </div>
      </div>
      <div className="App-Page">
        <Spritesheet
          image={CoworkerLandingPageSpriteSheet}
          widthFrame={512}
          heightFrame={512}
          steps={8}
          fps={10}
          direction={"forward"}
          loop={true}
        />
        <p>Cowerkerz</p>
        {getLink()}
        <Link
          className="App-Link"
          to={{
            pathname: "/dashboard",
            search: "guest=true",
          }}
        >
          Play as Guest
        </Link>
      </div>
    </>
  );
}

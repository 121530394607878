import React from "react";
import { GameStatus, IGameState, IRoundState } from "./Game.interfaces";
import "./Header.css";

export function Header(props: {
  startGame: () => void;
  address: string;
  areAllPlayersReady: () => boolean;
  gameState?: IGameState;
  roundState?: IRoundState;
}) {
  const { gameState, startGame, address, areAllPlayersReady, roundState } =
    props;

  const canGameStart = areAllPlayersReady() && gameState && gameState.playerPositions.length > 2;

  if (gameState) {
    const getStatus = () => {
      switch (gameState.status) {
        case GameStatus.notStarted:
          return gameState.host === address ? (
            <button
              disabled={!canGameStart}
              className="Status Game-Button"
              onClick={startGame}
            >
              Start Game
            </button>
          ) : (
            <div className="Status">{GameStatus.notStarted}</div>
          );
        case GameStatus.inProgress:
          return <div className="Status">{GameStatus.inProgress}</div>;
        case GameStatus.finished:
          return <div className="Status">{GameStatus.finished}</div>;
      }
    };

    return (
      <div className="Header">
        <div className="Header__Top">
          {`Game: ${gameState.id}`}
          {getStatus()}
        </div>
        <div className="Header__Bottom">{`Round ${roundState?.round}: ${roundState?.day}`}</div>
      </div>
    );
  }
  return null;
}
